export default {
  Monde: 'world',
  'Afrique du Nord et Moyen-Orient': 'Middle East & North Africa',
  'Afrique subsaharienne': 'Sub-Saharan Africa',
  'Amérique du Nord': 'North America',
  'Amérique latine et Caraïbes': 'Latin America & Caribbean',
  'Asie de l\'Est et Pacifique': 'East Asia & Pacific',
  'Asie du Sud': 'South Asia',
  'Europe et Asie centrale': 'Europe & Central Asia',
};
